import { useState } from 'react';
import { t } from 'bv-i18n';
import PropTypes from 'prop-types';
import StatementEntry from './statement_entry';
import SeeMoreModal from './see_more_modal';

const INITIAL_STATEMENTS_COUNT = 3;

const RebateActivity = ({ activity }) => {
  const [moreSeen, setMoreSeen] = useState(false);

  return (
    <li className="rebate-activity">
      <h4 className="statement-details-title bvs-h5">{ t('javascript.refer_a_friend.rebate_activity') }</h4>
      <ul>
        { activity
          .slice(0, INITIAL_STATEMENTS_COUNT)
          .map(({ accumulatedAmount, name }) => (
            <StatementEntry
              amount={accumulatedAmount}
              label={name}
              key={name}
            />
          ))}
      </ul>
      { activity.length > INITIAL_STATEMENTS_COUNT
            && <span className="statement-details-see-more bvs-link" onClick={() => setMoreSeen(true)}>{ t('see_more') }</span> }
      { moreSeen
            && (
            <SeeMoreModal
              onClose={() => setMoreSeen(() => false)}
              activity={activity}
            />
            ) }
    </li>
  );
};

export default RebateActivity;

RebateActivity.propTypes = {
  activity: PropTypes.arrayOf(Object).isRequired,
};
