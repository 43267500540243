import { createRef, Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'bv-components';
import { t } from 'bv-i18n';

class ReferralLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };

    this.linkText = createRef();
  }

  copyLink = () => {
    this.linkText.current.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    this.setState({ copied: true });

    if (!this.copyFeedbackTimeout) {
      this.copyFeedbackTimeout = setTimeout(() => {
        this.setState({
          copied: false,
        });
        delete this.copyFeedbackTimeout;
      }, 3000);
    }
  };

  copyButtonText = () => {
    const { copied } = this.state;
    return t(`javascript.refer_a_friend.${copied ? 'copy_button_feedback' : 'copy_button'}`);
  };

  shareInviteLink = async () => {
    const { link } = this.props;
    try {
      await navigator.share({ title: 'Invite Link', url: link });
    } catch (err) {
      //
    }
  };

  render() {
    const { link, shareable } = this.props;

    return (
      <div className="raf-link__copy">
        <input type="text" readOnly name="invite_link" className="bvs-input bvs-ellipsis" ref={this.linkText} value={link} />
        <Button secondary inverse label={this.copyButtonText()} onClick={this.copyLink} />
        { shareable && navigator.share && <Button secondary label={t('javascript.refer_a_friend.share_invite_link')} onClick={this.shareInviteLink} />}
      </div>
    );
  }
}

ReferralLink.propTypes = {
  link: PropTypes.string,
  shareable: PropTypes.bool,
};

ReferralLink.defaultProps = {
  link: 'Link not found',
  shareable: false,
};

export default ReferralLink;
