import PropTypes from 'prop-types';
import FaqDescription from 'ReferAFriend/components/faq_description';
import { Icon } from 'bv-components';
import { withFold } from 'bv-hocs';

const Faq = ({
  description, folded, title, toggleFolded,
}) => (
  <li className={`raf-faqs-list__item ${folded ? '' : 'is-active'}`}>
    <p className="bvs-h5" onClick={toggleFolded}>
      {title}
      <Icon type="raf-faq-arrow" className="raf-faqs__arrow" />
    </p>

    { folded || (<FaqDescription description={description} />)}
  </li>
);

Faq.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  folded: PropTypes.bool,
  toggleFolded: PropTypes.func,
};

Faq.defaultProps = {
  title: '',
  description: '',
  folded: true,
  toggleFolded: () => {},
};

export default withFold(Faq);
