import PropTypes from 'prop-types';
import { Icon, Button } from 'bv-components';

const Step = ({ stepName, status, ordinal }) => (
  <Button className="bvs-button-card raf_referral__statement-details-step">
    { status !== 'COMPLETED'
      ? <Icon className="is-check disabled statement-details-step-mark" />
      : <Icon className="is-check statement-details-step-mark" />}
    <span className="statement-details-step-content">
      {ordinal}
      .
      {' '}
      { stepName }
    </span>
  </Button>
);

Step.propTypes = {
  stepName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  ordinal: PropTypes.number.isRequired,
};

export default Step;
