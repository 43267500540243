import { useSelector } from 'react-redux';
import { withModalWindow } from 'bv-hocs';
import { t } from 'bv-i18n';
import { setFragment } from 'bv-helpers/location';
import { getSortModalOpen } from 'ReferAFriendAsia/selectors';
import ReferralStatements from './referral_statements';
import SortingModal from './sorting/modal_container';

const AllReferralsApp = () => {
  const sortModalOpen = useSelector(getSortModalOpen);

  return (
    <div className="raf_referrals__container raf_referrals-all-friends">
      <ReferralStatements />
      {sortModalOpen && <SortingModal />}
    </div>
  );
};

export default withModalWindow(t('javascript.refer_a_friend.all_friends'),
  {
    className: 'raf-modal-asia',
    onClose: () => setFragment('/refer_a_friend'),
    onBack: () => setFragment('/refer_a_friend'),
    showBack: true,
  })(AllReferralsApp);
