import { t } from 'bv-i18n';

const PromotionUnavailable = () => (
  <li className="raf_statement-entry__status">
    <span className="bvs-icon is-info" />
    <span>{ t('javascript.refer_a_friend.promotion_unavailable') }</span>
  </li>
);

export default PromotionUnavailable;
