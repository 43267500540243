import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { calculateProgressBarStatus } from 'ReferAFriendAsia/helpers';

const ProgressBar = ({ steps }) => {
  const { totalSteps, completedSteps, completedPercentage } = calculateProgressBarStatus(steps);

  return (
    <>
      <div className="statement-details-progress-status-line">
        <div className="status-line-inactive" />
        <div className="status-line-progressed" style={{ width: completedPercentage }} />
      </div>
      <span className="statement-details-progress-status-info">
        {t('javascript.refer_a_friend.completed_referral_steps', { completedSteps, totalSteps })}
      </span>
    </>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(Object).isRequired,
};

export default ProgressBar;
