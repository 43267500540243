import PropTypes from 'prop-types';

const Step = ({ text, extraInfo }) => (
  <li className="swipeable-step">
    <h4 className="raf-title">{text}</h4>
    { extraInfo && <div className="raf-extrainfo">{extraInfo}</div> }
  </li>
);

Step.defaultProps = {
  extraInfo: null,
};

Step.propTypes = {
  text: PropTypes.string.isRequired,
  extraInfo: PropTypes.string,
};

export default Step;
