import PropTypes from 'prop-types';
import { cmsAssetPath } from 'bv';

const BannerImage = ({ url, type }) => {
  if (!url) {
    return false;
  }

  return (
    <img
      alt="Banner"
      className={`raf-page__banner--${type === 'top' ? 'top' : 'referral'}`}
      src={cmsAssetPath(url)}
    />
  );
};

BannerImage.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['top', 'referral']).isRequired,
};

export default BannerImage;
