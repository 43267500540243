import PropTypes from 'prop-types';
import { toCurrency } from 'bv-helpers/number';

const StatementEntry = ({ label, amount }) => (
  <li className="raf_statement-entry">
    <span className="raf_statement-entry-label">{label}</span>
    <span className="raf_statement-entry-amount">{toCurrency(amount)}</span>
  </li>
);

StatementEntry.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default StatementEntry;
