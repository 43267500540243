import SkeletonColumn from 'BVComponents/skeletons/skeleton_column';

const StatementsSkeleton = () => (
  <div className="skeleton--raf-statement-details-wrapper">
    <div className="skeleton__content-wrapper flex--between">
      <SkeletonColumn colName="signup-bonus" />
      <SkeletonColumn colName="signup-bonus" />
    </div>
    <div className="skeleton__content-wrapper">
      <SkeletonColumn colName="steps" />
      <SkeletonColumn colName="steps" />
    </div>
    <div className="skeleton is-loading">
      <SkeletonColumn colName="steps-progressbar" />
      <SkeletonColumn colName="rebate-header" />
    </div>
    <div className="skeleton__content-wrapper flex--between">
      <SkeletonColumn colName="first-statement" />
      <SkeletonColumn colName="first-statement" />
    </div>
    <div className="skeleton__content-wrapper flex--between">
      <SkeletonColumn colName="second-statement" />
      <SkeletonColumn colName="second-statement" />
    </div>
    <div className="skeleton__content-wrapper flex--between">
      <SkeletonColumn colName="third-statement" />
      <SkeletonColumn colName="third-statement" />
    </div>
  </div>
);

export default StatementsSkeleton;
