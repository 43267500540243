import { useDispatch, useSelector } from 'react-redux';
import { getStatementsSorting } from 'ReferAFriendAsia/selectors';
import { setStatementsSortingBy, toggleSortModal } from 'ReferAFriendAsia/duck';
import ModalView from './modal_view';

const ModalContainer = () => {
  const dispatch = useDispatch();
  const { by } = useSelector(getStatementsSorting);

  return (
    <ModalView
      onClose={() => dispatch(toggleSortModal())}
      sortBy={(sortBy) => dispatch(setStatementsSortingBy({ by: sortBy }))}
      activeSortingMethod={by}
    />
  );
};

export default ModalContainer;
