import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useLockBodyScroll } from 'bv-hooks';
import { Modal, RenderInBody } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';

const SeeMoreModal = ({
  onClose,
  activity,
}) => {
  const scrollableRef = useRef();

  useLockBodyScroll(scrollableRef);

  return (
    <RenderInBody>
      <Modal
        title={t('javascript.refer_a_friend.rebate_activity')}
        iconClosable
        icon={false}
        className="list-modal raf-asia-rebate-menu"
        onCloseClick={onClose}
      >
        <div ref={scrollableRef}>
          <div className="list-modal-top-title-wrapper">
            <ul className="list-modal-items">
              { activity.map(({ accumulatedAmount, name }) => (
                <li className="list-modal-item recent" key={name}>
                  <span>{name}</span>
                  {' '}
                  <span>{toCurrency(accumulatedAmount)}</span>
                </li>
              )) }
            </ul>
          </div>
        </div>
      </Modal>
    </RenderInBody>
  );
};

SeeMoreModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  activity: PropTypes.arrayOf(Object).isRequired,
};

export default SeeMoreModal;
