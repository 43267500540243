import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const RafVideo = ({ videoUrl }) => (
  <>
    <h4 className="bvs-header raf-title">{t('javascript.refer_a_friend.video_title')}</h4>
    <div className="raf-page__section raf-video">
      <iframe src={videoUrl} />
    </div>
  </>
);

RafVideo.propTypes = {
  videoUrl: PropTypes.string,
};

RafVideo.defaultProps = {
  videoUrl: 'Link not found',
};

export default RafVideo;
