import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import ReferralLink from 'ReferAFriend/components/referral_link';
import { handleCta } from 'bv-services';
import QrCode from './qr_code';

const InviteFriends = ({
  canRef, link, qrCode,
}) => (
  <div className="bvs-card raf-page__section raf-page-share">
    { canRef ? (
      <>
        <p>{ t(`javascript.refer_a_friend.share_your_link${qrCode ? '_or_qr_code' : ''}`) }</p>
        {qrCode && <QrCode code={qrCode} />}
        <ReferralLink link={link} shareable />
      </>
    ) : (
      <div className="raf-page-message">
        <p dangerouslySetInnerHTML={{ __html: sanitize(t('javascript.refer_a_friend.cant_invite_friends')) }} />
        <Button secondary onClick={() => handleCta({ action: 'LIVECHAT' })} label={t('contact_us')} />
      </div>
    )}
  </div>
);

InviteFriends.propTypes = {
  canRef: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  qrCode: PropTypes.string.isRequired,
};

export default InviteFriends;
