import { t, locale } from 'bv-i18n';

export const registeredDateTime = (dateString) => {
  const dateTime = new Date(dateString);
  const date = dateTime.toLocaleDateString(locale(), { day: 'numeric', month: 'short', year: 'numeric' });
  const time = dateTime.toLocaleTimeString(locale(), { hour: '2-digit', minute: '2-digit' });

  return t('javascript.refer_a_friend.registered_at', { date, time });
};

export const calculateProgressBarStatus = (steps) => {
  const totalSteps = steps.length;
  const completedSteps = steps.filter(({ status }) => status === 'COMPLETED').length;
  const completedPercentage = (100 / totalSteps) * completedSteps;

  return { totalSteps, completedSteps, completedPercentage: `${completedPercentage}%` };
};

export const getSortingLabelByMethod = (activeSortingMethod) => {
  switch (activeSortingMethod) {
    case 'recent':
      return t('javascript.refer_a_friend.sorting_friends_by.recent');
    case 'topFriends':
      return t('javascript.refer_a_friend.sorting_friends_by.top_friends');
    case 'lastName':
      return t('last_name');
    default:
      return '';
  }
};

export const buildSortString = (sort) => {
  const direction = sort.desc ? 'desc' : 'asc';
  switch (sort.by) {
    case 'recent':
      return `registrationDate,${direction}`;
    case 'topFriends':
      return `accumulatedCredit,${direction}`;
    default:
      return null;
  }
};
