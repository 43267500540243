import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInfiniteScroll } from 'bv-hooks';
import { SmallSpinner, Spinner } from 'bv-components';
import { loadStatements, resetStatements } from 'ReferAFriendAsia/duck';
import {
  getStatementsLoading,
  getLoading,
  getStatements,
  getTotalStatements,
  getSorting,
} from 'ReferAFriendAsia/selectors';
import { buildSortString } from 'ReferAFriendAsia/helpers';
import Header from './header';
import ReferralStatementContainer from '../main/track_your_referrals/referral_statement_container';

const ReferralStatements = () => {
  const listRef = useRef(null);
  const [page, setPage] = useState(0);
  const statements = useSelector(getStatements);
  const totalStatements = useSelector(getTotalStatements);
  const loading = useSelector(getStatementsLoading);
  const nextStatementsLoading = useSelector(getLoading);
  const sortBy = useSelector(getSorting);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetStatements());
    setPage(0);
    dispatch(loadStatements(0, buildSortString(sortBy)));
  }, [sortBy]);

  useEffect(() => {
    if (page > 0) dispatch(loadStatements(page, buildSortString(sortBy)));
  }, [page]);

  const onEndOfElement = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useInfiniteScroll(true, onEndOfElement, listRef, totalStatements, { throttleSpeed: 700 });

  if (loading) return <Spinner />;

  return (
    <>
      <Header />
      <div className="bvs-card">
        <ul className="raf_referral__statements" ref={listRef}>
          { statements.map(({
            accountId, registrationDate, status, firstName,
          }) => (
            <ReferralStatementContainer
              accountId={accountId}
              name={accountId}
              registrationDate={registrationDate}
              status={status}
              firstName={firstName}
              key={accountId}
            />
          ))}
        </ul>
      </div>
      <div className="raf_referral__spinner-wrapper">
        { nextStatementsLoading && <SmallSpinner /> }
      </div>
    </>
  );
};

export default ReferralStatements;
