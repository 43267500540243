import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { getStatementsSorting, getTotalStatements } from 'ReferAFriendAsia/selectors';
import { setStatementsSortingBy, toggleSortModal } from 'ReferAFriendAsia/duck';
import { getSortingLabelByMethod } from 'ReferAFriendAsia/helpers';

const Header = () => {
  const dispatch = useDispatch();
  const { desc, by } = useSelector(getStatementsSorting);
  const totalStatements = useSelector(getTotalStatements);

  return (
    <div className="all-referrals-header sort-filter">
      <div className="sort-filter__label">
        <strong>{t('javascript.refer_a_friend.total_friends')}</strong>
        {' '}
        <span>
          (
          { totalStatements }
          )
        </span>
      </div>
      <div className="sort-filter__title">
        <span>{t('sort_by')}</span>
        {' '}
        <span
          className="bvs-link"
          onClick={() => dispatch(toggleSortModal())}
        >
          { getSortingLabelByMethod(by) }
        </span>
        {' '}
      </div>
      <span
        className={classnames('sort-filter__order', { ascending: !desc, descending: desc })}
        onClick={() => dispatch(setStatementsSortingBy({ desc: !desc }))}
      />
    </div>
  );
};

export default Header;
