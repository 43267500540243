import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { Icon } from 'bv-components';
import StatementsSummary from 'ReferAFriend/components/statements_summary';

const Statements = ({ summary }) => {
  const [statementsOpen, setStatementsOpen] = useState(false);

  return summary && (
    <>
      <header className="raf-page-activity-header">
        <h4 className="bvs-header raf-title">{t('javascript.refer_a_friend.track_your_activity')}</h4>
        <Icon className={`is-arrow-solid-circle-${statementsOpen ? 'down' : 'up'}`} onClick={() => setStatementsOpen(!statementsOpen)} />
      </header>
      <div className={classnames('bvs-card raf-page__section raf-page-statement', { hidden: statementsOpen })}>
        <StatementsSummary summary={summary} hasIcons />
      </div>
    </>
  );
};

Statements.propTypes = {
  summary: PropTypes.instanceOf(Object),
};

Statements.defaultProps = {
  summary: null,
};

export default Statements;
