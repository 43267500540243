import PropTypes from 'prop-types';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';
import StatementsEntry from './statements_entry';

const StatementsSummary = ({
  summary: {
    completed,
    numberOfFriends,
    totalReward,
  },
  hasIcons,
}) => (
  <div className="raf-page__statement heading">
    <StatementsEntry
      icon={hasIcons && 'is-raf'}
      label={t('javascript.refer_a_friend.reccomended')}
      value={t('javascript.refer_a_friend.friends', { numberOfFriends })}
    />
    {numberOfFriends > 0
        && (
        <StatementsEntry
          icon={hasIcons && 'is-referral'}
          label={t('javascript.refer_a_friend.completed_referrals')}
          value={String(completed)}
        />
        )}
    <StatementsEntry
      icon={hasIcons && 'is-offers'}
      label={t('javascript.refer_a_friend.received')}
      value={t('javascript.refer_a_friend.bonus_received', { amount: toCurrency(totalReward) })}
    />
  </div>
);

StatementsSummary.defaultProps = {
  hasIcons: false,
};

StatementsSummary.propTypes = {
  summary: PropTypes.instanceOf(Object).isRequired,
  hasIcons: PropTypes.bool,
};

export default StatementsSummary;
