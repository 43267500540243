import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import Step from './step';
import ProgressBar from './progress_bar';
import PromotionUnavailable from './promotion_unavailable';

const Steps = ({ steps, promoStatus }) => (
  promoStatus !== 'FAILED'
    ? (
      <div className="raf_referral__statement-details-steps">
        <Carousel embedded scrollStep={100}>
          { steps.map((step, ordinal) => (
            <Step {...step} ordinal={ordinal + 1} key={step.stepName} />)) }
        </Carousel>
        <ProgressBar steps={steps} />
      </div>
    )
    : <PromotionUnavailable />
);

Steps.propTypes = {
  steps: PropTypes.arrayOf(Object).isRequired,
  promoStatus: PropTypes.string.isRequired,
};

export default Steps;
