import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import StatementEntry from './statement_entry';
import Steps from './steps/steps';
import StatementsSkeleton from './statements_skeleton';
import RebateActivity from './rebate_activity';

const StatementDetails = ({
  signupAward, steps, activity, promoStatus, rebateActivityEnabled, loading,
}) => {
  if (loading) return <StatementsSkeleton />;

  return (
    <ul className="raf_referral__statement-details">
      <StatementEntry amount={signupAward} label={t('javascript.refer_a_friend.signup_bonus')} />
      <Steps steps={steps} promoStatus={promoStatus} />
      { rebateActivityEnabled
          && activity.length > 0
          && <RebateActivity activity={activity} /> }
    </ul>
  );
};

StatementDetails.propTypes = {
  signupAward: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(Object).isRequired,
  activity: PropTypes.arrayOf(Object).isRequired,
  promoStatus: PropTypes.string.isRequired,
  rebateActivityEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default StatementDetails;
