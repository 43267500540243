import {
  fetchStatements,
  fetchUserConfiguration,
  fetchStatementDetails,
} from './api';

const SET_USER_CONFIGURATION = 'referAFriendAsia/SET_USER_CONFIGURATION';
const SET_STATEMENTS = 'referAFriendAsia/SET_STATEMENTS';
const APPEND_STATEMENTS = 'referAFriendAsia/APPEND_STATEMENTS';
const RESET_STATEMENTS = 'referAFriendAsia/RESET_STATEMENTS';
const APPEND_STATEMENT_DETAILS = 'referAFriendAsia/APPEND_STATEMENT_DETAILS';
const SET_STATEMENT_DETAILS_LOADING = 'referAFriendAsia/SET_STATEMENT_DETAILS_LOADING';
const SET_LOADING = 'referAFriendAsia/SET_LOADING';
const SET_STATEMENTS_SORTING_BY = 'referAFriendAsia/SET_STATEMENTS_SORTING_BY';
const TOGGLE_SORT_MODAL = 'referAFriendAsia/TOGGLE_SORT_MODAL';

const setUserConfiguration = (payload) => ({
  type: SET_USER_CONFIGURATION,
  payload,
});

const setStatements = (payload) => ({
  type: SET_STATEMENTS,
  payload,
});

const appendStatements = (payload) => ({
  type: APPEND_STATEMENTS,
  payload,
});

const appendStatementDetails = (payload) => ({
  type: APPEND_STATEMENT_DETAILS,
  payload,
});

const setStatementDetailsLoading = (payload) => ({
  type: SET_STATEMENT_DETAILS_LOADING,
  payload,
});

const setLoading = () => ({
  type: SET_LOADING,
});

export const toggleSortModal = () => ({
  type: TOGGLE_SORT_MODAL,
});

export const setStatementsSortingBy = (payload) => ({
  type: SET_STATEMENTS_SORTING_BY,
  payload,
});

export const resetStatements = () => ({
  type: RESET_STATEMENTS,
});

export const loadStatements = (page, sort) => async (dispatch, getState) => {
  const state = getState().rafAsia;

  const dispatchByPage = (_page, statements) => {
    if (_page === 0) return dispatch(setStatements(statements));
    return dispatch(appendStatements(statements));
  };

  dispatch(setLoading());

  if (!state.userConfiguration) {
    const userConfiguration = await fetchUserConfiguration();
    dispatch(setUserConfiguration(userConfiguration));
    const statements = await fetchStatements(page, sort);
    return dispatchByPage(page, statements);
  }

  const statements = await fetchStatements(page, sort);
  return dispatchByPage(page, statements);
};

export const loadUserConfiguration = () => async (dispatch) => {
  const userConfiguration = await fetchUserConfiguration();
  return dispatch(setUserConfiguration(userConfiguration));
};

export const loadStatementDetails = ({ referralId }) => async (dispatch) => {
  dispatch(setStatementDetailsLoading(referralId));
  try {
    const statementDetails = await fetchStatementDetails(referralId);
    dispatch(appendStatementDetails({ referralId, ...statementDetails }));
  } catch {
    dispatch(appendStatementDetails({ referralId, error: true }));
  }
};

export const initialState = {
  userConfiguration: null,
  statements: {
    content: [],
    pageable: {
      pageNumber: 0,
    },
    sort: {
      by: 'recent',
      desc: true,
    },
    sortModalOpen: false,
    totalElements: 0,
    loading: true,
  },
  statementDetails: {
    content: [],
  },
  loading: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_STATEMENTS: {
      return {
        ...state,
        loading: false,
        statements: {
          ...state.statements,
          ...action.payload,
          loading: false,
        },
      };
    }
    case APPEND_STATEMENTS: {
      const { content } = action.payload;
      return {
        ...state,
        loading: false,
        statements: {
          ...state.statements,
          content: [...state.statements.content, ...content],
        },
      };
    }
    case RESET_STATEMENTS: {
      return {
        ...state,
        statements: {
          ...state.statements,
          content: [],
          loading: true,
        },
      };
    }
    case APPEND_STATEMENT_DETAILS: {
      const { error = false } = action.payload;
      const newStatement = { ...action.payload, loading: error, error };
      return {
        ...state,
        statementDetails: {
          content: state.statementDetails.content.map((statement) => (
            statement.referralId === action.payload.referralId
              ? newStatement
              : statement)),
        },
      };
    }
    case SET_STATEMENT_DETAILS_LOADING: {
      return {
        ...state,
        statementDetails: {
          content: [
            ...state.statementDetails.content,
            { referralId: action.payload, loading: true },
          ],
        },
      };
    }
    case SET_USER_CONFIGURATION: {
      const { content, totalElements } = action.payload.statements;
      return {
        ...state,
        userConfiguration: {
          faq: [],
          promotion: {},
          ...action.payload,
          statements: content,
        },
        statements: {
          ...state.statements,
          totalElements,
        },
        loading: false,
      };
    }
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_STATEMENTS_SORTING_BY:
      return {
        ...state,
        statements: {
          ...state.statements,
          sort: {
            ...state.statements.sort,
            ...action.payload,
          },
          sortModalOpen: false,
        },
      };
    case TOGGLE_SORT_MODAL:
      return {
        ...state,
        statements: {
          ...state.statements,
          sortModalOpen: !state.statements.sortModalOpen,
        },
      };
    default:
      return state;
  }
};
