import { createSelector } from 'reselect';

export const getUserConfiguration = (state) => state.rafAsia.userConfiguration;
export const getRebateActivityEnabled = (
  state) => state.rafAsia.userConfiguration.rebateActivityEnabled;
export const getStatements = (state) => state.rafAsia.statements.content;
export const getTotalStatements = (state) => state.rafAsia.statements.totalElements;
export const getStatementsSorting = (state) => state.rafAsia.statements.sort;
export const getLoading = (state) => state.rafAsia.loading;
export const getStatementsLoading = (state) => state.rafAsia.statements.loading;
export const getSortModalOpen = (state) => state.rafAsia.statements.sortModalOpen;
export const getSorting = (state) => state.rafAsia.statements.sort;
const getStatementDetails = (state) => state.rafAsia.statementDetails.content;
const getReferralId = (state, userId) => userId;

export const getStatementDetailsByRefId = createSelector(
  [getStatementDetails, getReferralId],
  (statements, referralId) => statements.find(
    (statement) => (!statement.error && statement.referralId === referralId)),
);
