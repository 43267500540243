import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import BannerImage from '../shared/banner_image';
import ReferralStatementContainer from './referral_statement_container';

const Referrals = ({ bannerImage, statements, totalStatements }) => (
  <>
    <h4 className="bvs-header raf-title">{t('javascript.refer_a_friend.track_your_referrals')}</h4>
    <div className="raf_referrals__container">
      <BannerImage url={bannerImage} type="referral" />
      <div className="bvs-card">
        <ul className="raf_referral__statements">
          {
            statements.map(
              ({
                accountId, registrationDate, status, firstName,
              }) => (
                <ReferralStatementContainer
                  accountId={accountId}
                  registrationDate={registrationDate}
                  status={status}
                  firstName={firstName}
                  key={accountId}
                />
              ))
          }
        </ul>
        { totalStatements > 5 && (
          <div className="raf_referrals__show-more-wrapper">
            <Button to="/refer_a_friend/all-referrals" primary>{ t('javascript.refer_a_friend.see_all_your_friends') }</Button>
          </div>
        ) }
      </div>
    </div>
  </>
);

Referrals.propTypes = {
  bannerImage: PropTypes.string.isRequired,
  statements: PropTypes.arrayOf(Object).isRequired,
  totalStatements: PropTypes.number.isRequired,
};

export default Referrals;
