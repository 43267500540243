import { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLockBodyScroll } from 'bv-hooks';
import { Modal, RenderInBody } from 'bv-components';
import { getSortingLabelByMethod } from 'ReferAFriendAsia/helpers';

const ModalView = ({
  onClose,
  sortBy,
  activeSortingMethod,
}) => {
  const scrollableRef = useRef();

  useLockBodyScroll(scrollableRef);

  return (
    <RenderInBody>
      <Modal
        info
        iconDotsClosable
        icon={false}
        className="list-modal raf-asia-dot-menu"
        onCloseClick={onClose}
      >
        <div ref={scrollableRef}>
          <div className="list-modal-top-title-wrapper">
            <ul className="list-modal-items">
              {
                ['recent', 'topFriends'].map((sortMethod) => (
                  <li
                    onClick={() => sortBy(sortMethod)}
                    className={classnames('list-modal-item', { active: activeSortingMethod === sortMethod })}
                    key={sortMethod}
                  >
                    { getSortingLabelByMethod(sortMethod) }
                  </li>
                ),
                )
              }
            </ul>
          </div>
        </div>
      </Modal>
    </RenderInBody>
  );
};

ModalView.propTypes = {
  onClose: PropTypes.func.isRequired,
  sortBy: PropTypes.func.isRequired,
  activeSortingMethod: PropTypes.string.isRequired,
};

export default ModalView;
