import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'bv-components';

const StatementsEntry = ({
  label, value, className, icon,
}) => (
  <div className={classnames('flex', className)}>
    { icon && <Icon alternative className={icon} /> }
    <span className="raf-content__label">{label}</span>
    <span className="raf-content__value">{value}</span>
  </div>
);

StatementsEntry.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
};

StatementsEntry.defaultProps = {
  className: '',
  icon: null,
};

export default StatementsEntry;
