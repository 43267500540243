import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Card } from 'bv-components';

const FaqDescription = ({ description }) => (
  <Card className="raf-faqs-list__item-content">
    <ReactMarkdown
      source={description}
      softBreak="br"
    />
  </Card>
);

FaqDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default FaqDescription;
