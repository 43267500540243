import PropTypes from 'prop-types';

const QrCode = ({ code }) => (
  <div className="raf-qr-code">
    <img src={code} alt="" />
  </div>
);

QrCode.propTypes = {
  code: PropTypes.string,
};

QrCode.defaultProps = {
  code: '',
};

export default QrCode;
