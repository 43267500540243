import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';
import { registeredDateTime } from 'ReferAFriendAsia/helpers';
import StatementDetails from './statement_details';

const ReferralStatement = ({
  firstName,
  registrationDate,
  status,
  toggleDetails,
  expanded,
  signupAward,
  steps,
  activity,
  promoStatus,
  rebateActivityEnabled,
  loading,
}) => (
  <li>
    <ul className="raf_referral__statement-overview">
      <Icon className={`bvs-link is-arrow-solid-circle-${expanded ? 'up' : 'down'}`} onClick={toggleDetails} />
      <div className="raf_referral-info">
        <h4 className="bvs-h4">{firstName}</h4>
        <span>{registeredDateTime(registrationDate)}</span>
      </div>
      {rebateActivityEnabled
          && (
          <div className="raf_referral-status">
            <div className={`bvs-label ${status.toLowerCase()}`}>
              {t(`javascript.refer_a_friend.referral_status.${status.toLowerCase()}`)}
            </div>
          </div>
          )}
    </ul>
    {expanded && (
    <StatementDetails
      signupAward={signupAward}
      steps={steps}
      activity={activity}
      promoStatus={promoStatus}
      rebateActivityEnabled={rebateActivityEnabled}
      loading={loading}
    />
    )}
  </li>
);

ReferralStatement.propTypes = {
  firstName: PropTypes.string.isRequired,
  registrationDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  signupAward: PropTypes.number.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(Object).isRequired,
  activity: PropTypes.arrayOf(Object).isRequired,
  promoStatus: PropTypes.string.isRequired,
  rebateActivityEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ReferralStatement;
