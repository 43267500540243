import { useContext } from 'react';
import { Button, Icon } from 'bv-components';
import { ModalWindowContext } from 'bv-contexts';
import { t } from 'bv-i18n';

const BackToTopButton = () => {
  const modal = useContext(ModalWindowContext).scrollableRef.current;

  const scrollToTop = () => modal.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <div className="raf-asia-backtotop">
      <Button secondary inverse className="raf-asia-backtotop__button" onClick={scrollToTop}>
        { t('javascript.refer_a_friend.back_to_top') }
        <Icon id="arrow-anchor-up" />
      </Button>
    </div>
  );
};

export default BackToTopButton;
