import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFragment } from 'bv-helpers/location';
import { withModalWindow } from 'bv-hocs';
import { Spinner } from 'bv-components';
import FaqList from 'ReferAFriend/components/faq_list';
import RafVideo from 'ReferAFriend/components/raf_video';
import Terms from 'ReferAFriend/components/terms';
import BackToTopButton from './terms/back_to_top_button';
import InviteFriends from './invite_friends/invite_friends';
import Statements from './track_your_activity/statements';
import Referrals from './track_your_referrals/referrals';
import SwipeableSteps from './top_section/swipeable_steps';
import BannerImage from './shared/banner_image';
import { getTotalStatements, getUserConfiguration } from '../../selectors';
import { loadUserConfiguration } from '../../duck';

const App = () => {
  const dispatch = useDispatch();
  const userConfiguration = useSelector(getUserConfiguration);
  const {
    topBannerImage, referralBannerImage, canRef, content,
    promotion, qrCode, link, shareIcons, faqs, termsAndConditions, summary, statements, videoUrl,
  } = userConfiguration || {};
  const totalStatements = useSelector(getTotalStatements);

  useEffect(() => {
    dispatch(loadUserConfiguration());
  }, []);

  if (!userConfiguration) {
    return <Spinner />;
  }

  return (
    <div className="raf-page__wrapper">
      <BannerImage url={topBannerImage} type="top" />
      <div className="raf-page__container">
        {canRef && (
          <SwipeableSteps
            firstStep={content.firstStep}
            secondStep={content.secondStep}
            thirdStep={content.thirdStep}
            promotion={promotion}
          />
        )}
        <InviteFriends qrCode={qrCode} link={link} shareIcons={shareIcons} canRef={canRef} />
        <Statements summary={summary} />
        { statements.length > 0 && (
          <Referrals
            bannerImage={referralBannerImage}
            statements={statements}
            totalStatements={totalStatements}
          />
        ) }
        {videoUrl && (
        <RafVideo
          videoUrl={videoUrl}
        />
        )}
        <FaqList faqs={faqs} />
        <Terms termsAndConditions={termsAndConditions} promotion={promotion} />
        <BackToTopButton />
      </div>
    </div>
  );
};

export default withModalWindow(
  'javascript.refer_a_friend.modal_title',
  { className: 'raf-modal-asia', onClose: () => setFragment('/') },
)(App);
