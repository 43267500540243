import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { loadStatementDetails } from 'ReferAFriendAsia/duck';
import { getStatementDetailsByRefId, getRebateActivityEnabled } from 'ReferAFriendAsia/selectors';
import ReferralStatement from './referral_statement';

const ReferralStatementContainer = ({
  accountId, registrationDate, status, firstName,
}) => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const statementDetails = useSelector((state) => getStatementDetailsByRefId(state, accountId));
  const rebateActivityEnabled = useSelector(getRebateActivityEnabled);

  const toggleDetails = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (expanded && !statementDetails) {
      dispatch(loadStatementDetails({ referralId: accountId }));
    }
  }, [expanded]);

  return (
    <ReferralStatement
      expanded={expanded}
      toggleDetails={toggleDetails}
      firstName={firstName}
      registrationDate={registrationDate}
      status={status}
      signupAward={statementDetails?.parentAwardAmount}
      steps={statementDetails?.steps || []}
      activity={statementDetails?.activity || []}
      promoStatus={statementDetails?.status}
      rebateActivityEnabled={rebateActivityEnabled}
      loading={!statementDetails || statementDetails?.loading}
    />
  );
};

ReferralStatementContainer.propTypes = {
  accountId: PropTypes.number.isRequired,
  registrationDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
};

export default ReferralStatementContainer;
