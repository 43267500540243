import { Route, Switch } from 'react-router-dom';
import Main from './components/main/app';
import AllReferrals from './components/all_referrals/app';
import reducer from './duck';

const { addReducers } = window.reduxState;

addReducers({
  rafAsia: reducer,
});

const ReferAFriendAsiaApp = () => (
  <Switch>
    <Route path="/refer_a_friend" component={Main} exact />
    <Route path="/refer_a_friend/all-referrals" component={AllReferrals} exact />
  </Switch>
);

export default ReferAFriendAsiaApp;
