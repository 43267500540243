import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';
import { ExpandCollapseCard } from 'bv-components';

const Terms = ({
  termsAndConditions,
  promotion: {
    awardAmount,
    parentAwardAmount,
  },
}) => {
  if (!termsAndConditions) {
    return null;
  }

  const source = termsAndConditions
    .replace(/%{award_amount}/g, toCurrency(awardAmount))
    .replace(/%{parent_award_amount}/g, toCurrency(parentAwardAmount));

  return (
    <>
      <h4 className="bvs-header raf-title">{t('javascript.refer_a_friend.terms_and_conditions_title')}</h4>
      <ExpandCollapseCard fadeOutOnEnd>
        <ReactMarkdown
          source={source}
          softBreak="br"
        />
      </ExpandCollapseCard>
    </>
  );
};

Terms.propTypes = {
  promotion: PropTypes.instanceOf(Object).isRequired,
  termsAndConditions: PropTypes.string,
};

Terms.defaultProps = {
  termsAndConditions: '',
};

export default Terms;
