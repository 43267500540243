import PropTypes from 'prop-types';

import { t } from 'bv-i18n';
import Faq from 'ReferAFriend/components/faq';

const FaqList = ({ faqs }) => (
  faqs?.length > 0 && (
  <>
    <h4 className="bvs-header raf-title">{t('javascript.refer_a_friend.faq_title')}</h4>
    <div className="raf-page__section raf-faqs">
      <ul className="raf-faqs-list">
        {faqs.map((faq) => (
          <Faq
            key={faq.title}
            title={faq.title}
            description={faq.description}
          />
        ))}
      </ul>
    </div>
  </>
  )
);

FaqList.propTypes = {
  faqs: PropTypes.instanceOf(Array).isRequired,
};

export default FaqList;
